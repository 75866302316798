<template>
  <div>
    <div class="searchForm">
      <div class="columns is-mobile is-mulitline">
        <div v-if="showDateInput" class="column">
          <v-date-picker
            mode="single"
            v-model="dateOne"
            :value="formatDates(dateOne)"
          >
            <p class="control has-icons-left">
              <input
                type="text"
                class="input is-small"
                :id="'datepicker-trigger' + fieldId"
                readonly
                :value="formatDates(dateOne)"
              />
              <span class="icon is-small is-left">
                <font-awesome-icon :icon="['fas', 'calendar-alt']" />
              </span>
            </p>
          </v-date-picker>
        </div>

        <div v-if="showDateInput && meetingtypeId === 3" class="column">
          <v-date-picker
            mode="single"
            v-model="dateTwo"
            :value="formatDates(dateTwo)"
          >
            <p class="control has-icons-left">
              <input
                type="text"
                class="input is-small"
                :id="'datepicker-trigger' + fieldId"
                readonly
                :value="formatDates(dateTwo)"
              />
              <span class="icon is-small is-left">
                <font-awesome-icon :icon="['fas', 'calendar-alt']" />
              </span>
            </p>
          </v-date-picker>
        </div>

        <div v-if="meetingtypeId < 3" class="column">
          <div class="control has-icons-left">
            <div
              class="select is-fullwidth is-small"
              :class="{ 'is-danger': endTimeErrors.length }"
            >
              <select v-model="mStartTime">
                <option
                  v-for="timeSlot in timeSlots"
                  :key="timeSlot"
                  :value="timeSlot"
                  :selected="mStartTime === timeSlot"
                >
                  {{ minutesToTime(timeSlot) }}
                </option>
              </select>
            </div>
            <div
              v-if="endTimeErrors.length"
              class="has-text-danger is-size-7"
              v-html="endTimeErrors"
            ></div>
            <div class="icon is-small is-left">
              <font-awesome-icon :icon="['fas', 'clock']" />
            </div>
          </div>
        </div>
        <div class="has-margin-top">—</div>
        <div v-if="meetingtypeId < 3" class="column">
          <div class="control has-icons-left">
            <div
              class="select is-fullwidth is-small"
              :class="{ 'is-danger': endTimeErrors.lenth }"
            >
              <select
                v-model="mEndTime"
                :error="endTimeErrors"
                @change="$v.mEndTime.$touch()"
              >
                <option
                  v-for="timeSlot in timeSlots"
                  :key="timeSlot"
                  :value="timeSlot"
                  :selected="mEndTime === timeSlot"
                >
                  {{ minutesToTime(timeSlot) }}
                </option>
              </select>
            </div>
            <div class="icon is-small is-left">
              <font-awesome-icon :icon="['fas', 'clock']" />
            </div>
          </div>
        </div>

        <div v-if="meetingtypeId !== 2" class="column">
          <p class="control has-icons-left">
            <input
              type="text"
              v-model.number="mSeats"
              @keyup="getLocationSpaces"
              @keyup.13="getAvailability"
              class="input is-small"
              :class="{ 'is-danger': seatsErrors.length }"
              @input="
                () => {
                  $v.mSeats.$touch()
                }
              "
              @blur="$v.mSeats.$touch()"
            />
            <span class="icon is-small is-left">
              <font-awesome-icon :icon="['fas', 'user-check']" />
            </span>
          </p>
          <div
            v-if="seatsErrors.length"
            class="has-text-danger is-size-7"
            v-html="seatsErrors"
          ></div>
        </div>

        <div class="column">
          <div class="control has-icons-left">
            <div
              class="select is-fullwidth is-small"
              :class="{ 'is-loading': isLoadingSpaces }"
            >
              <select v-model="mSpaceId">
                <option :value="0" :select="mSpaceId === 0">
                  {{
                    $t(
                      'Components.Availability.CheckAvailability.Select_OptionAllSpaces'
                    )
                  }}
                </option>
                <option
                  v-for="space in filteredSpaces"
                  :key="space.Id"
                  :value="space.Id"
                  :select="mSpaceId === space.Id"
                >
                  {{ space.Name }}
                </option>
              </select>
            </div>
            <div class="icon is-small is-left">
              <font-awesome-icon :icon="['fas', 'chair']" />
            </div>
          </div>
        </div>

        <div class="column">
          <div class="control has-icons-left">
            <div
              v-if="meetingtypeId === 1"
              class="select is-fullwidth is-small"
            >
              <select v-model="mSettingId">
                <option :value="0" :selected="mSettingId === 0">
                  {{
                    $t(
                      'Components.Availability.CheckAvailability.Select_OptionAllConfigurations'
                    )
                  }}
                </option>
                <option
                  v-for="setting in filteredSettings"
                  :key="setting.SettingId"
                  :value="setting.SettingId"
                  :selected="setting.SettingId === mSettingId"
                >
                  {{ setting.SettingId | getSettingName }}
                </option>
              </select>
            </div>
            <div v-if="meetingtypeId === 1" class="icon is-small is-left">
              <font-awesome-icon :icon="['fas', 'sliders-h']" />
            </div>
          </div>
        </div>
      </div>

      <div class="has-text-right">
        <button
          @click="getAvailability"
          class="button"
          :class="{
            'is-loading': isSearching,
            'is-success': !blockSearchButton,
            'is-disabled': blockSearchButton,
          }"
          :disabled="blockSearchButton"
        >
          {{ $t('Components.Availability.CheckAvailability.Button_Search') }}
        </button>
      </div>
    </div>

    <hr />
    <ui-loader v-if="isSearching" />
    <template v-if="!isSearching && filteredAvailability.Locations">
      <nav v-if="selectedSpaces.length" class="navbar is-hidden-print">
        <a @click="availability = {}" class="navbar-item">
          <span class="icon is-small">
            <font-awesome-icon :icon="['fas', 'chevron-left']" />
          </span>
          <span>{{
            $t('Components.Reservation.ReservationMailLog.Icon_Back')
          }}</span>
        </a>
      </nav>

      <AvailableLocation
        v-for="location in filteredAvailability.Locations"
        :key="location.Id"
        :availableLocation="location"
        :meetingtypeId="meetingtypeId"
        :selectedSpaceId="mSpaceId"
      />
    </template>
  </div>
</template>

<script>
import Vue from 'vue'
import { EventBus } from '@/eventbus/event-bus'
import { mapState, mapMutations } from 'vuex'
import availabilityProvider from '../../providers/availability'
import spaceProvider from '@/providers/space'
import { minValue, required } from 'vuelidate/lib/validators'
import VCalendar from 'v-calendar'
Vue.use(VCalendar, {})

const touchMap = new WeakMap()
import AvailableLocation from '@/components/Availability/AvailableLocation'

export default {
  name: 'CheckAvailability',

  components: {
    AvailableLocation,
  },

  props: {
    autoSearch: {
      default: false,
      type: Boolean,
    },
    canChangeDate: {
      default: false,
      type: Boolean,
    },
    channelId: {
      default: 0,
      type: Number,
    },
    countryId: {
      default: 0,
      type: Number,
    },
    endDate: {
      default: function () {
        return new Date()
      },
      type: Date,
    },
    endTime: {
      default: 1020,
      type: Number,
    },
    locationId: {
      default: 0,
      type: Number,
    },
    meetingtypeId: {
      default: 1,
      type: Number,
    },
    seats: {
      default: 1,
      type: Number,
    },
    settingId: {
      default: 0,
      type: Number,
    },
    showDateInput: {
      default: false,
      type: Boolean,
    },
    startDate: {
      default: function () {
        return new Date()
      },
      type: Date,
    },
    startTime: {
      default: 540,
      type: Number,
    },
    tenderId: {
      default: 0,
      type: Number,
    },
    voucherId: {
      default: 0,
      type: Number,
    },
    selectedSpaces: {
      default: function () {
        return []
      },
      type: Array,
    },
  },

  data() {
    return {
      availability: {},
      availabilityChecked: false,
      closeTime: 0,
      dateOne: this.startDate,
      dateTwo: this.endDate,
      fieldId: new Date().getTime(),
      isLoadingSettings: false,
      isLoadingSpaces: false,
      isSearching: false,
      mEndTime: this.endTime,
      mSeats: this.meetingtypeId === 2 ? 1 : this.seats,
      mSettingId: this.settingId,
      mSpaceId: 0,
      mStartTime: this.startTime,
      openTime: 0,
      searchTerm: '',
      settings: [],
      spaces: [],
      timeSlots: [],
    }
  },

  /**
   * Form validation rules
   */
  validations() {
    let output = {
      mEndTime: {
        minValue: (value) => value > this.mStartTime,
      },

      mSeats: {
        required,
        minValue: minValue(1),
      },
    }
    return output
  },

  computed: {
    ...mapState('spaceStore', ['spacesPageState']),

    filteredAvailability() {
      let availability = this.availability

      if (
        this.availability &&
        this.availability.Locations &&
        this.availability.Locations.length > 0 &&
        this.selectedSpaces &&
        this.selectedSpaces.length > 0
      ) {
        let location = this.availability.Locations.find(
          (l) => l.LocationId == this.locationId
        )

        let spaces = []
        spaces = location.Spaces.filter((s) => {
          return !this.selectedSpaces.find((sp) => {
            return (
              sp.SpaceId === s.SpaceId &&
              ((sp.StartMinutes >= s.StartMinutes &&
                sp.StartMinutes <= s.EndMinutes) ||
                (sp.EndMinutes >= s.StartMinutes &&
                  sp.EndMinutes <= s.EndMinutes))
            )
          })
        })

        let locationIndex = this.availability.Locations.findIndex(
          (l) => l.LocationId == this.locationId
        )
        availability.Locations[locationIndex].Spaces = spaces
        this.$emit('callbackShowSelectedSpaces', false)
      }

      return availability
    },

    filteredSpaces() {
      let spaces = []

      if (this.spaces.length > 0) {
        spaces = this.spaces

        if (this.meetingtypeId > 0) {
          spaces = spaces.filter((s) =>
            s.Meetingtypes.some((m) => m.MeetingtypeId === this.meetingtypeId)
          )
        }
      }

      return spaces
    },

    filteredSettings() {
      let settings = this.settings

      if (this.mSpaceId > 0) {
        let spaceIndex = this.spaces.findIndex((s) => s.Id === this.mSpaceId)
        if (spaceIndex > -1) {
          let space = this.spaces[spaceIndex]
          settings = space.Settings
        }
      }

      if (this.mSeats > 0 && this.mSpaceId > 0) {
        settings = settings.filter(
          (s) => s.MinSeats <= this.mSeats && s.MaxSeats >= this.mSeats
        )
      }

      return settings
    },

    blockSearchButton() {
      let self = this
      return self.seatsErrors.length > 0 || self.endTimeErrors.length > 0
    },

    endTimeErrors() {
      let errors = ''
      if (!this.$v.mEndTime.$dirty) return errors
      if (!this.$v.mEndTime.minValue) {
        errors = this.$t(
          'Components.Availability.CheckAvailability.Message_TimeError'
        )
      }
      return errors
    },

    seatsErrors() {
      let errors = ''
      if (!this.$v.mSeats.$dirty) return errors
      if (!this.$v.mSeats.required) {
        errors = this.$t(
          'Components.Availability.CheckAvailability.Message_Required'
        )
      }
      if (!this.$v.mSeats.minValue) {
        errors = this.$t(
          'Components.Availability.CheckAvailability.Message_SeatsError'
        )
      }
      return errors
    },
  },

  created() {
    this.fillTimeSlots()

    if (this.spacesPageState.spaces.length === 0) {
      this.getLocationSpaces()
    }

    EventBus.$on('spaceSelected', this.setAvailability)

    if (!this.spaces) {
      this.getLocationSpaces()
    }
  },

  mounted() {
    if (this.autoSearch) {
      this.getAvailability()
    }
  },

  beforeDestroy() {
    this.availability = {}

    EventBus.$off('spaceSelected', this.setAvailability)
  },

  methods: {
    ...mapMutations('spaceStore', ['setSpacesPageState']),

    setAvailability() {
      this.availability = {}
    },

    formatDates(dateOne = '', dateTwo = '') {
      let formattedDates = ''

      if (dateOne) {
        formattedDates = this.$options.filters.dateFormat(
          dateOne,
          this.$i18n.locale,
          'longDateFormat'
        )
      }
      return formattedDates
    },

    fillTimeSlots() {
      let max = 1440
      let minutes = 30

      let i = 0
      while (i <= max) {
        this.timeSlots.push(i)
        i = i + minutes
      }
    },

    minutesToTime(minutes) {
      let hour = Number(Math.floor(minutes / 60))
      let minute = Number(minutes - hour * 60)

      return (
        (hour < 10 ? '0' : '') + hour + ':' + (minute < 10 ? '0' : '') + minute
      )
    },

    getLocationSpaces() {
      let self = this
      self.isLoadingSpaces = true

      spaceProvider.methods
        .getLocationSpaces(self.locationId, '', 0, self.mSeats, 1, 50)
        .then((response) => {
          if (response.status === 200) {
            self.spacesPageState.spaces = response.data.Results
            self.setSpacesPageState(self.spacesPageState)

            //this.spaces = response.data.Results
          }
        })
        .finally(() => {
          this.isLoadingSpaces = false
        })
    },

    getActiveSpaceConfigurations() {
      this.isLoadingSettings = true

      spaceProvider.methods
        .getActiveSpaceConfigurations(this.mLocationId)
        .then((response) => {
          if (response.status === 200) {
            for (let i = 0; i < response.data.length; i++) {
              let settingId = response.data[i]
              this.settings.push({
                SettingId: settingId,
                MinSeats: 0,
                MaxSeats: 0,
              })
            }
          }
        })
        .finally(() => {
          this.isLoadingSettings = false
        })
    },

    getAvailability() {
      let self = this
      self.availability = {}
      this.$v.$touch()
      if (this.$v.$invalid) {
        // 'ERROR'
        self.isSearching = false
        this.$emit('callbackShowSelectedSpaces', true)
      } else {
        self.isSearching = true
        this.$emit('callbackShowSelectedSpaces', false)

        let startDate = self.$options.filters.dateObjectIsoDateString(
          self.dateOne
        )
        let endDate = self.$options.filters.dateObjectIsoDateString(
          self.dateTwo
        )

        availabilityProvider.methods
          .getAvailability(
            startDate,
            self.meetingtypeId < 3 ? startDate : endDate,
            self.channelId,
            self.countryId,
            self.locationId,
            self.searchTerm,
            self.meetingtypeId,
            self.meetingtypeId === 2 ? 1 : self.mSeats,
            self.mStartTime,
            self.mEndTime,
            self.meetingtypeId === 2 ? 0 : self.mSettingId,
            self.mSpaceId,
            self.voucherId,
            self.tenderId,
            -100,
            this.$i18n.locale
          )
          .then((response) => {
            self.availability = response.data

            if (self.availability && self.availability.Locations.length > 0) {
              let searchId = self.availability.Locations[0].SearchId
              self.$emit('searchInitiated', searchId)
            }
          })
          .finally(() => {
            self.isSearching = false
            // this.$emit('callbackShowSelectedSpaces', false)
            self.availabilityChecked = true
          })
      }
    },
  },
}
</script>
